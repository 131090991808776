import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
// import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const cgu = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Conditions Générales d'Utilisation - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">Conditions Générales d'Utilisation</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGameLeft"><br/>
          <br/>
          <br/>
          <b>Bienvenue dans la communauté Plaiz !</b> <br/>
            <br/>
            Nous sommes ravis que vous ayez choisi de nous rejoindre et d’utiliser nos services (ci-après les « Services »). <br/>
            <br/>
            Nous vous présentons les conditions des Services (appelées « Conditions »), qui se lisent comme un contrat ayant force obligatoire entre vous et Plaiz SAS. Le respect de ces Conditions est primordial pour que la communauté profite au max de Plaiz. <br/>
            <br/>
            Les présentes Conditions d’utilisation définissent les droits et obligations des utilisateurs (vous). <br/>
            L’utilisation des Services Plaiz implique donc l’acceptation des présentes Conditions. A défaut d’acceptation, vous n’aurez pas accès à nos Services et vous ne pourrez pas intégrer la communauté Plaiz :( . <br/>
            <br/>
            Plaiz est une application permettant à ses utilisateurs de se rejoindre une communauté de passionnés de mode afin d'échanger de manière Constructive (!!) sur des styles, looks, concepts... <br/>
            Nous encourageons la bienveillance entre utilisateurs et la liberté d'expression, dans la Limite Du Raisonnable (!!)<br/>
            <br/>
            <br/>
            <br/>
            <b>Conditions fondamentales </b><br/>
            <br/>
            1. Vous devez être âgé(e) d’au moins 13 ans pour utiliser les Services. Si vous avez moins de 18 ans, assurez-vous d’obtenir l’autorisation de votre responsable légal. <br/>
            <br/>​
            2. Acceptant les présentes Conditions, vous confirmez que vous avez la capacité de conclure un contrat ayant force obligatoire avec Plaiz SAS. <br/>
            <br/>​
            3. Si vous utilisez les Services pour le compte d’une personne morale, vous confirmez que vous êtes habilité pour accepter les Conditions. <br/>
            <br/>
            4. Plaiz SAS vous accorde une licence personnelle, mondiale, libre de redevance, non cessible, non exclusive, révocable et ne pouvant pas faire l'objet d'une sous-licence, d'accès et d'utilisation des Services. Cette licence a uniquement pour but de vous permettre d'utiliser et de profiter des avantages des Services, conformément aux présentes Conditions. Nous vous informons que les logiciels que nous vous fournissons peuvent télécharger et installer automatiquement des mises à niveau et des mises à jour, ou d'autres nouvelles fonctions, mais vous pouvez modifier et ajuster ces téléchargements automatiques dans les réglages de votre appareil. Vous ne pouvez pas copier, modifier, distribuer, vendre, ou louer tout ou partie de nos Services. Vous ne pouvez pas non plus procéder à une opération d'ingénierie inverse ou tenter d'extraire le code source des logiciels, sauf si les lois interdisent ces restrictions, ou si nous vous avons donné notre permission par écrit. Vous n’êtes pas autorisé(e) à changer, modifier, adapter ou altérer le Service, ni à changer, modifier ou altérer un autre site web en vue de faire croire qu’il est associé au Service ou à Plaiz. <br/>
            <br/>
            5. Nombre de nos Services vous permettent de créer, charger, publier, envoyer, recevoir et stocker des contenus (photos, vidéos, textes, ci-après les Contenus). Lorsque vous le faites, vous conservez tous les droits de propriété sur ces contenus. Vous nous accordez néanmoins une licence d'utilisation de ces contenus. L'étendue de cette licence dépend des Services que vous utilisez et des Réglages que vous avez sélectionnés. Pour tous les Services, vous accordez à Plaiz une licence mondiale, libre de redevance, cessible et pouvant faire l'objet d'une sous-licence, autorisant l'hébergement, le stockage, l'utilisation, l'affichage, la reproduction, la modification, l'adaptation, l'édition, la publication et la distribution de ces contenus aussi longtemps que vous utilisez les Services. Cette licence a pour seul but de permettre l'exploitation, le développement, l'exécution, la promotion et l'amélioration des Services existants, ainsi que la recherche et le développement de nouveaux Services. <br/>
            Du fait que le Service Newsfeed (écran sur lequel il est possible de visionner et d’interagir avec le contenu que les utilisateurs créent) soit alimenté par les utilisateurs, ait intrinsèquement un caractère public et traite de sujets d'intérêt public, vous nous accordez également le droit de promouvoir, d'exposer, de diffuser, de regrouper, de sous-licencier, de publier et d'exécuter ou d'afficher publiquement le contenu soumis via les Services Newsfeed ou tout autre Service alimenté par les utilisateurs, ou d'en créer des oeuvres dérivées, et ce, quels que soient la forme, le support ou la méthode de diffusion (qui sont actuellement connus ou seront développés à l'avenir). Lorsque vous apparaissez dans du contenu alimenté par les utilisateurs ou lorsque vous créez, téléchargez, publiez ou envoyez un tel contenu, vous accordez à Plaiz le droit illimité à l'échelle mondiale d'utiliser votre nom ou votre image, dans toute la mesure nécessaire. Cela signifie, entre autres, que vous n'aurez droit à aucune rémunération de la part de Plaiz si votre nom ou votre image sont utilisés dans le cadre des Services Newsfeed ou autres Services alimentés par les utilisateurs <br/>
            <br/>
            6. Bien que nous ne soyons pas obligés de le faire, nous pouvons consulter, réviser, filtrer et supprimer votre contenu à tout moment et pour quelque raison que ce soit, y compris si nous pensons que votre contenu viole les présentes Conditions. Vous êtes cependant le seul responsable du contenu que vous créez, téléchargez, publiez, stockez ou envoyez via les Services. Les Services peuvent contenir des annonces publicitaires. En contrepartie de l'autorisation que vous accorde Plaiz d'accéder aux Services et de les utiliser, vous consentez à ce que nous, Plaiz et ses partenaires tiers insèrent de la publicité dans les Services, y compris des publicités personnalisées sur la base des informations que vous nous fournissez, que nous collectons ou que nous obtenons auprès de vous. Comme les Services contiennent du contenu fourni par vous et d'autres utilisateurs, de la publicité peut parfois apparaître en regard de votre contenu. <br/>
            <br/>
            7. L’affectation de l’utilisateur à une Communauté (groupe d’utilisateur qui partage des intérêts que nous définissons) se base sur les informations que vous indiquez et les données que nous récoltons. Nous nous réservons le droit d’utiliser ces informations afin d’améliorer nos Services et votre expérience d’utilisateur. <br/>
            <br/>​
            8. Nous sommes toujours ravis de connaître l'avis de nos utilisateurs. Toutefois, si vous nous faites part de votre avis ou de suggestions, sachez que nous pouvons utiliser vos idées sans vous rémunérer. <br/>
            <br/>
            9. Vous n’êtes pas autorisé(e) à publier, par le biais du Service, de scènes de nudité partielle ou totale, ni de photo ou contenu à caractère discriminatoire, illicite, frauduleux, haineux ou pornographique ou sexuellement suggestif. <br/>
            <br/>​
            10. Vous ne devez pas diffamer, poursuivre, intimider, harceler ni menacer des personnes ou des entités, ni en usurper l’identité. De plus, vous ne devez pas publier d’informations privées ou confidentielles par le biais du Service (y compris, mais sans s’y limiter, les coordonnées de carte de crédit, les numéros de sécurité sociale, les numéros de carte d’identité, les numéros de téléphone privés et les adresses e-mail privées qui vous appartiennent ou qui appartiennent à des tiers). <br/>
            <br/>
            11. Vous n’êtes pas autorisé(e) à utiliser ce Service à des fins illicites ou interdites. vous acceptez de vous conformer à toutes les lois, règles et réglementations qui s’appliquent à votre utilisation du Service et de votre Contenu (tel qu’il est défini ci-dessous), notamment les droits d’auteur. <br/>
            <br/>​
            12. Vous êtes responsable de toutes les activités entreprises avec votre compte et vous acceptez de ne pas vendre, transférer, concéder sous licence ni attribuer à qui que ce soit votre compte, vos cercles, votre nom d’utilisateur et les droits liés à votre compte. Sauf aux personnes ou entreprises expressément autorisées à créer des comptes au nom de leurs employés ou clients, Plaiz interdit la création de comptes pour autrui. Vous acceptez donc de ne pas créer de compte pour une tierce personne. Vous affirmez aussi que toutes les informations que vous fournissez ou avez fournies à Plaiz lors de votre inscription et à tout autre moment sont vraies, précises, actuelles et exhaustives, et vous acceptez de mettre à jour vos données comme il convient afin d’en conserver l’authenticité et la précision. Vous ne devez pas créer de comptes auprès du Service par le biais de méthodes non autorisées, notamment, mais sans s’y limiter, en utilisant des outils d’accès automatisé (script, robot, araignée, robot d’indexation, etc.). <br/>
            <br/>
            13. Vous vous engagez à ne pas solliciter, collecter ou utiliser les identifiants de connexion d’autres utilisateurs Plaiz. <br/>
            <br/>
            14. Vous être responsable de la confidentialité et de la sécurité de votre mot de passe. Aussi, nous vous conseillons de ne pas le divulguer, ni à titre gratuit, ni à titre onéreux. En cas de problèmes avec votre compte, vous engagerez directement votre responsabilité. <br/>
            <br/>
            15. Vous êtes le ou la seule responsable de votre comportement et de l’ensemble des données, textes, fichiers, informations, nom d’utilisateur, images, graphismes, photos, profils, créations originales, liens et autres contenus ou supports (ci-après dénommés collectivement le « Contenu ») que vous soumettez, publiez ou affichez sur le Service ou par son intermédiaire. <br/>
            <br/>
            16. Vous ne devez pas créer ni soumettre de messages électroniques, commentaires, mentions « J’aime » ou autres formes de communications commerciales non sollicitées ou insistantes (c’est-à-dire du « courrier indésirable ») aux utilisateurs de Plaiz. <br/>
            <br/>
            17. Vous n’êtes pas autorisé(e) à utiliser des noms de domaine ou des URL de sites web dans votre nom d’utilisateur sans la permission écrite préalable de Plaiz. <br/>
            <br/>
            18. Vous ne devez pas interférer avec le fonctionnement du Service, des serveurs ou des réseaux connectés au Service, notamment en transmettant des vers, virus, logiciels espions, logiciels malveillants ou tout autre code de nature perturbante ou destructrice. Vous ne devez pas injecter de contenu ni de code dans les pages Plaiz, vous ne devez pas les modifier et vous ne devez pas interférer avec leur fonctionnement ou leur affichage dans le navigateur ou sur le périphérique des utilisateurs. <br/>
            <br/>
            19. Vous devez respecter les Règles de la communauté Plaiz, disponibles ci-dessous. <br/>
            <br/>
            20. Vous ne devez pas tenter d’empêcher ni t’enter d’empecher un autre utilisateur d’utiliser le Service ni d’en bénéficier, et vous ne devez pas encourager ni faciliter des infractions aux présentes Conditions d’utilisation ni aux autres conditions de Plaiz. Vous ne devez en aucun cas tenter de restreindre l’accès d’autrui à son compte Plaiz contre rémunération ou à titre gratuit. <br/>
            <br/>
            21. Toute infraction à ces Conditions d’utilisation peut entraîner, à la seule discrétion de Plaiz, la résiliation de votre compte Plaiz. Vous comprenez et acceptez le fait que Plaiz ne saurait être tenu responsable du Contenu publié sur le Service et que vous utilisez le Service à vos propres risques. Si vous enfreignez la lettre ou l’esprit des présentes Conditions, ou créez autrement un risque de poursuites à l’encontre de Plaiz, nous pouvons cesser de vous fournir tout ou partie du Service. <br/>
            <br/>
            <br/>
            <br/>
            Allez, bonne utilisation la famille! On vous aime! <br/>
            <br/>
            <b>La team Plaiz</b>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default cgu

const styles = {
  backToHomeLink: {
    color: "white",
  },
}